<template>
  <v-row>
    <v-col class="py-0 pr-0 d-flex align-center justify-start">
      {{ title }}
      <span
        v-if="display && isMediumBreakpointOrLarger"
        class="ml-2 primary--text"
      >
        {{ text }}
      </span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    display: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    isMediumBreakpointOrLarger () {
      return this.$vuetify.breakpoint.name !== 'sm' && this.$vuetify.breakpoint.name !== 'xs'
    },
  }
}
</script>